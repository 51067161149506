const inputs = document.querySelectorAll('input[type="password"]')

inputs.forEach(function(input) {
  input.nextElementSibling?.addEventListener('click', function(e: Event) {
    const type = input.getAttribute('type') === 'password' ? 'text' : 'password'
    input.setAttribute('type', type)
    
    const target = e.target as HTMLImageElement
    const showPath = 'assets/media/static/icons/show-pass.svg'
    const hidePath = 'assets/media/static/icons/hide-pass.svg'
    target.setAttribute('src', type === 'password' ? showPath : hidePath)
  })
})
